/* eslint-disable max-len */
// tslint:disable:variable-name
import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {
    IDealerToManage,
    IValidatorMap,
    IDealerCreditTierAPR,
    IDealerMileages,
    IDealerTerms,
    IUpdateDealerCallbackFn,
    IValidateCallbackFn,
    ISaveDealerCallbackFn,
    IUploadBrandingExpCallbackFn,
    PartnerMapping,
    IUpdatePartnerMappingsCallbackFn,
    IdPartnerMapping
} from '../store/manager/types';
import { Box, Typography } from '@material-ui/core';
import { FeatureToggles } from '@makemydeal/dr-feature-toggles-client';

import AccountStatus from './dealerSiteFields/AccountStatus';
import ApplyTaxesAndFees from './dealerSiteFields/ApplyTaxesAndFees';
import AreDealerSettingOverridesEnabled from './internalFields/areDealerSettingOverridesEnabled';
import CalculateOnMSRP from './dealerSiteFields/CalculateOnMSRP';
import CashDownMethodFinancing from './dealerSiteFields/CashDownMethodFinancing';
import CashDownMethodLeasing from './dealerSiteFields/CashDownMethodLeasing';
import ConsentType from './dealerSiteFields/ConsentType';
import CreditAndFinancingNewDropdown from './dealerSiteFields/CreditAndFinancingNewDropdown';
import CreditAndFinancingUsedDropdown from './dealerSiteFields/CreditAndFinancingUsedDropdown';
import CustomizedPrivacyNotice from './dealerSiteFields/CustomizedPrivacyNotice';
import DealershipNameField from './dealerSiteFields/DealershipName';
import DealershipAddressField from './dealerSiteFields/DealershipAddress';
import DealershipAddress2Field from './dealerSiteFields/DealershipAddress2';
import DealershipCityField from './dealerSiteFields/DealershipCity';
import DealershipCreditApplicationURLButtonText from './dealerSiteFields/CreditApplicationButtonText';
import DealershipCreditApplicationURLField from './dealerSiteFields/DealershipCreditApplicationURL';
import DealershipPhoneField from './dealerSiteFields/DealershipPhone';
import DealershipStateField from './dealerSiteFields/DealershipState';
import DealershipWebsiteField from './dealerSiteFields/DealershipWebsite';
import DealershipZipField from './dealerSiteFields/DealershipZip';
import DealershipShopperEducationVideoURLField from './dealerSiteFields/DealershipShopperEducationVideoURL';
import DefaultPaymentType from './dealerSiteFields/DefaultPaymentType';
import DepositType from './dealerSiteFields/DepositType';
import DownPaymentFixedAmountNew from './dealerSiteFields/DownPaymentFixedAmountNew';
import DownPaymentFixedAmountUsed from './dealerSiteFields/DownPaymentFixedAmountUsed';
import DownPaymentPercent from './dealerSiteFields/DownPaymentPercent';
import DownPaymentPercentUsed from './dealerSiteFields/DownPaymentPercentUsed';
import DueAtSigning from './dealerSiteFields/DueAtSigning';
import DisplayUnifIDealSummary from './dealerSiteFields/DisplayUnifyDealSummary';
import EcommercePartnerDropdown from './dealerSiteFields/EcommercePartnerDropdown';
import EcommerceURL from './dealerSiteFields/EcommerceURL';
import EcommerceCTACopy from './dealerSiteFields/EcommerceCTACopy';
import EnableSoftPull from './dealerSiteFields/EnableSoftPull';
import EnableCheckoutAnywhereInMV from './dealerSiteFields/EnableCheckoutAnywhereInMV';
import FinancingMileageLimit from './dealerSiteFields/FinancingMileageLimit';
import FinancingPriceLimit from './dealerSiteFields/FinancingPriceLimit';
import FinancingYearLimit from './dealerSiteFields/FinancingYearLimit';
import FinancingDefaultTermDropdowns from './dealerSiteFields/FinancingDefaultTermDropdowns';
import GlobalShortDealerDisclaimer from './dealerSiteFields/GlobalShortDealerDisclaimer';
import GlobalLongDealerDisclaimer from './dealerSiteFields/GlobalLongDealerDisclaimer';
import HasCreditDecision from './dealerSiteFields/HasCreditDecision';
import HasLeasing from './dealerSiteFields/HasLeasing';
import HoldPeriod from './dealerSiteFields/HoldPeriod';
import HowToApplyDownPayment from './dealerSiteFields/IsDueAtSigninAmountDeduct';
import CreditProvider from './dealerSiteFields/CreditProvider';
import IsContinueToDeskingDisabled from './dealerSiteFields/IsContinueToDeskingDisabled';
import IsDefaultHeaderTextEnabled from './dealerSiteFields/IsDefaultHeaderTextEnabled';
import IsMenuPricingBasedOn from './dealerSiteFields/IsMenuPricingBasedOn';
import IsMenuPricingDisabled from './dealerSiteFields/IsMenuPricingDisabled';
import IsEmployerAddressRequired from './dealerSiteFields/IsEmployerAddressRequired';
import IsTCPAEnabled from './dealerSiteFields/IsTCPAEnabled';
import IsTCPACheckboxDisabled from './dealerSiteFields/IsTCPACheckboxDisabled';
import IsTestDealer from './dealerSiteFields/IsTestDealer';
import LeasingDownPaymentFixedAmount from './dealerSiteFields/LeasingDownPaymentFixedAmount';
import LeasingDownPaymentPercent from './dealerSiteFields/LeasingDownPaymentPercent';
import LeasingMileageDefaultDropdown from './dealerSiteFields/LeasingMileageDefaultDropdown';
import LeasingTermDefaultDropdown from './dealerSiteFields/LeasingTermDefaultDropdown';
import LeasePaymentBasedOnMsrp from './dealerSiteFields/LeasePaymentBasedOnMsrp';
import LocationToSendLeadsNew from './dealerSiteFields/LocationToSendLeadsNew';
import LocationToSendLeadsUsed from './dealerSiteFields/LocationToSendLeadsUsed';
import MultipleSecurityDepositLease from './dealerSiteFields/MultipleSecurityDepositLease';
import NewVehiclePoorCreditDropdown from './dealerSiteFields/NewVehiclePoorCreditDropdown';
import OverrideDRSActiveField from './dealerSiteFields/OverrideDRSActive';
import ReservationType from './dealerSiteFields/ReservationType';
import ReservationAmount from './dealerSiteFields/ReservationAmount';
import ShouldSendCreditDecisionEmail from './dealerSiteFields/ShouldSendCreditDecisionEmail';
import SponsorSetting from './dealerSiteFields/SponsorSetting';
import ProductSet from './dealerSiteFields/ProductSet';
import TCPAConsentText from './dealerSiteFields/TCPAConsentText';
import TestDriveAvailability from './dealerSiteFields/TestDriveAvailability';
import TestDriveServiceProvider from './dealerSiteFields/TestDriveServiceProvider';
import UseDealertrackLeadOverride from './dealerSiteFields/UseDealertrackLeadOverride';
import UsedVehiclePoorCreditDropdown from './dealerSiteFields/UsedVehiclePoorCreditDropdown';
import UseZipCodeService from './dealerSiteFields/UseZipCodeService';
import VantivAcceptorId from './dealerSiteFields/VantivAcceptorId';
import VantivAccountId from './dealerSiteFields/VantivAccountId';
import VantivAccountToken from './dealerSiteFields/AccountToken';
import VantivTerminalId from './dealerSiteFields/VantivTerminalId';
import HasTestDriveAtHome from './dealerSiteFields/HasTestDriveAtHome';
import EnableItemizedManufacturerIncentives from './dealerSiteFields/EnableItemizedManufacturerIncentives';
import { DR3FieldWrapper } from './common/DR3FieldWrapper';
import { DR3LeasingMonthlyTermsTable } from './common/DR3LeasingMonthlyTermsTable';
import { DR3LeasingTermsMileagesTable } from './common/DR3LeasingTermsMileagesTable';
import { DR3CreditTierTable } from './common/DR3CreditTierTable';
import { Options } from 'material-table';
import themeOverride from './layout/theme';
import VinSolutionsCRMIntegration from './dealerSiteFields/VinSolutionsCRMIntegration';
import IsAccelerateCreditAppEnabled from './dealerSiteFields/IsAccelerateCreditAppEnabled';
import IsConsumerFacingCreditDecisionEnabled from './dealerSiteFields/IsConsumerFacingCreditDecisionEnabled';
import ConsumerFacingCreditDecisionType from './dealerSiteFields/ConsumerFacingCreditDecisionType';
import DealLookupExperience from './dealerSiteFields/DealLookupExperience';
import SdpModalZIndex from './dealerSiteFields/SdpModalZIndex';
import IsAccelerateCreditDecisionEnabled from './dealerSiteFields/IsAccelerateCreditDecisionEnabled';
import StandaloneAccelerateCreditAppUrl from './dealerSiteFields/StandaloneAccelerateCreditAppUrl';
import IsCCPAEnabled from './dealerSiteFields/IsCCPAEnabled';
import IsDocUploadEnabled from './dealerSiteFields/IsDocUploadEnabled';
import IsPhoneNumberRequired from './dealerSiteFields/IsPhoneNumberRequired';
import IsMenuFitToHeightEnabled from './dealerSiteFields/IsMenuFitToHeightEnabled';
import IsVehicleDeliveryEnabled from './dealerSiteFields/IsVehicleDeliveryEnabled';
import ShowroomExperience from './dealerSiteFields/ShowroomExperience';
import IsActivEngageEnabled from './dealerSiteFields/IsActivEngageEnabled';
import IsPhoneRequiredForConsentDisplay from './dealerSiteFields/IsPhoneRequiredForConsentDisplay';
import { DR3PartnerMappingTable } from './common/DR3PartnerMappingTable';
import { IsOccIntegrationEnabled } from './dealerSiteFields/EnableOccIntegration';
import { ShowCreditApplicationDisclaimer } from './dealerSiteFields/ShowCreditApplicationDisclaimer';
import { EnableSwitchVehicle } from './dealerSiteFields/EnableSwitchVehicle';
import DealCentralCMSIntegration from './dealerSiteFields/DealCentralCMSIntegration';
import { ShowDealPulseInManagerView } from './dealerSiteFields/ShowDealPulseInManagerView';
import { ShowFlexiblePaymentOptions } from './dealerSiteFields/ShowFlexiblePaymentOptions';
import EnableLeadFormBeforeCreditApp from './dealerSiteFields/EnableLeadFormBeforeCreditApp';
import IsTargetPlatformR1J from './dealerSiteFields/IsTargetPlatformR1J';
import { getQuickLinkValue, getValidateForId } from './utilities/getIdMappings';
import { getHandleChangeForId } from './utilities/getHandleChangeForId';
import { EnableVauto } from './dealerSiteFields/EnableVauto';
import EnableGhostVin from './dealerSiteFields/EnableGhostVin';
import PrintSignatureLine from './dealerSiteFields/PrintSignatureLine';
import ShowPrintItemizedDealBreakdown from './dealerSiteFields/ShowPrintItemizedDealBreakdown';
import EnableDarwin from './dealerSiteFields/EnableDarwin';
import EnableGenerateContracts from './dealerSiteFields/EnableGenerateContracts';
import EnableCheckoutAnywhereInSV from './dealerSiteFields/EnableCheckoutAnywhereInSV';
import DealScreenExperience from './dealerSiteFields/DealScreenExperience';
import PaymentRange from './dealerSiteFields/PaymentRange';
import FinanceRangeAmount from './dealerSiteFields/FinanceRangeAmount';
import LeaseRangeAmount from './dealerSiteFields/LeaseRangeAmount';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(4),
        color: theme.palette.text.primary
    },
    adminMessage: {
        paddingTop: theme.spacing(4)
    }
}));

export interface IGlobalFieldsListUIProps {
    featureToggles?: FeatureToggles;
    dealer: IDealerToManage | null;
    validatorMap: IValidatorMap;
    updateDealerCallback: IUpdateDealerCallbackFn;
    validateCallback: IValidateCallbackFn;
    saveDealerCallback: ISaveDealerCallbackFn;
    uploadBrandingExpCallback: IUploadBrandingExpCallbackFn;
    admin: boolean;
    adminInternal?: boolean;
    dealerFieldsListSelected: boolean;

    updatePartnerMappingsCallback: IUpdatePartnerMappingsCallbackFn;
    integrators: any;
    partnerMappings: PartnerMapping[];
}

export interface IPartnerMapListUIProps {
    updatePartnerMappingsCallback: IUpdatePartnerMappingsCallbackFn;
    dealerFieldsListSelected: boolean;
    integrators: any;
    partnerMappings: PartnerMapping[];
    dealer: IDealerToManage | null;
    updateDealerCallback: IUpdateDealerCallbackFn;
}

interface IGlobalFieldsWrapperProps {
    classes: ReturnType<typeof useStyles>;
}

const GlobalFieldsWrapper: FunctionComponent<IGlobalFieldsWrapperProps> = ({ classes, children }) => {
    return (
        <div className={`${classes.root} globalFieldsWrapper`}>
            <Paper className={classes.paper}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {children}
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
};
interface IGlobalFieldsSectionProps {
    title: string;
    hidden?: boolean;
    subtitle?: string;
    paddingTopProp?: string;
}
const GlobalFieldsSection: FunctionComponent<IGlobalFieldsSectionProps> = ({
    title,
    hidden,
    subtitle,
    paddingTopProp,
    children
}) => {
    const id = title.split(' ').join('');
    const paddingTop = paddingTopProp ? paddingTopProp : '3.5em';
    if (hidden) {
        return null;
    } else {
        return (
            <Box id={id} paddingTop={paddingTop}>
                <Typography align="center" gutterBottom variant="h4">
                    {title}
                </Typography>
                <Typography align="center" gutterBottom variant="h5" color="inherit">
                    {subtitle}
                </Typography>
                {children}
            </Box>
        );
    }
};

const DR3LeasingTermsList: FunctionComponent<IGlobalFieldsListUIProps> = (props) => {
    if (!props.dealer) {
        return null;
    }
    const mileageTermsColumn: any = [{ title: 'Miles', field: 'mileage' }];
    const monthlyTermsColumn: any = [{ title: 'Months', field: 'months' }];
    const handleMileageUpdate = (newData: IDealerMileages, oldData: IDealerMileages) => {
        if (!props.dealer) {
            return null;
        }
        const mileages = props.dealer.leasing.mileages;
        const index = mileages.findIndex((term) => term.mileage === oldData.mileage && term.isDefault === oldData.isDefault);
        mileages[index] = newData;
        props.updateDealerCallback(null, { ...props.dealer });
    };
    const handleMonthlyTermUpdate = (newData: IDealerTerms, oldData: IDealerTerms) => {
        if (!props.dealer) {
            return null;
        }
        const terms = props.dealer.leasing.terms;
        const index = terms.findIndex((term) => term.months === oldData.months && term.isDefault === oldData.isDefault);
        terms[index] = newData;
        props.updateDealerCallback(null, { ...props.dealer });
    };
    const handleAddNewMileageTerm = (newData: IDealerMileages) => {
        if (!props.dealer) {
            return null;
        }
        const newMileageTerm: IDealerMileages = {
            isBase: false,
            isDefault: false,
            _id: '',
            mileage: newData.mileage
        };
        const mileageTerms = props.dealer.leasing.mileages;
        mileageTerms.push(newMileageTerm);
        props.updateDealerCallback(null, { ...props.dealer });
    };
    const handleAddNewMonthlyterm = (newData: IDealerTerms) => {
        if (!props.dealer) {
            return null;
        }
        const newMonthlyTerm: IDealerTerms = {
            isDefault: false,
            _id: '',
            months: newData.months
        };
        const terms = props.dealer.leasing.terms;
        terms.push(newMonthlyTerm);
        const leasing = {
            ...props.dealer.leasing,
            terms
        };
        const newDealer = {
            ...props.dealer,
            leasing
        };
        props.updateDealerCallback(null, newDealer);
    };
    const handleDeleteMileageTerm = (oldData: IDealerMileages) => {
        if (!props.dealer) {
            return null;
        }
        const mileageTerms: IDealerMileages[] = props.dealer.leasing.mileages;
        const index = mileageTerms.findIndex((term) => term.mileage === oldData.mileage && term.isDefault === oldData.isDefault);
        if (index < 0) return;

        mileageTerms.splice(index, 1);
        props.updateDealerCallback(null, { ...props.dealer });
    };
    const handleDeleteMonthlyTerm = (oldData: IDealerTerms) => {
        if (!props.dealer) {
            return null;
        }
        const monthlyTerms: IDealerTerms[] = props.dealer.leasing.terms;
        const index = monthlyTerms.findIndex((term) => term.months === oldData.months && term.isDefault === oldData.isDefault);
        if (index < 0) return;

        monthlyTerms.splice(index, 1);
        props.updateDealerCallback(null, { ...props.dealer });
    };
    const getMonthlyTermsTableOptions = () => {
        let monthlyTermsTableOptions: Options<any> = {};
        if (props.dealer) {
            monthlyTermsTableOptions = {
                pageSize: props.dealer.leasing.terms.length,
                search: false
            };
        }
        return monthlyTermsTableOptions;
    };
    const getMileageTermsTableOptions = () => {
        let mileageTermsTableOptions: Options<any> = {};
        if (props.dealer) {
            mileageTermsTableOptions = {
                pageSize: props.dealer.leasing.mileages.length,
                search: false
            };
        }
        return mileageTermsTableOptions;
    };
    return (
        <div>
            <DR3FieldWrapper label="Leasing Terms" leasingData={props.dealer.leasing}>
                <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
                    <DR3LeasingMonthlyTermsTable
                        leasingData={props.dealer.leasing}
                        columns={monthlyTermsColumn}
                        onUpdate={handleMonthlyTermUpdate}
                        onAdd={handleAddNewMonthlyterm}
                        onDelete={handleDeleteMonthlyTerm}
                        label="Monthly Terms"
                        tableOptions={getMonthlyTermsTableOptions()}
                    />
                    <DR3LeasingTermsMileagesTable
                        leasingData={props.dealer.leasing}
                        columns={mileageTermsColumn}
                        onUpdate={handleMileageUpdate}
                        onAdd={handleAddNewMileageTerm}
                        onDelete={handleDeleteMileageTerm}
                        label="Mileage Terms"
                        tableOptions={getMileageTermsTableOptions()}
                    />
                </div>
            </DR3FieldWrapper>
        </div>
    );
};

const DR3CreditTierList: FunctionComponent<IGlobalFieldsListUIProps> = (props) => {
    if (!props.dealer) {
        return null;
    }
    const creditTierNames: string[] = [];
    const columns: any = [{ title: 'Term', field: 'term' }];
    props.dealer.creditTiers = Object.values(props.dealer.creditTiers);
    props.dealer.creditTiers.forEach((creditTier) => {
        if (!creditTierNames[creditTierNames.indexOf(creditTier.name)]) {
            creditTierNames.push(creditTier.name);
        }
    });

    const createCreditTierTables = (creditTierName: string) => {
        if (props.dealer) {
            let newTier: any = null;
            let usedTier: any = null;
            props.dealer.creditTiers.forEach((element) => {
                if (element.type === 'new' && element.name === creditTierName) {
                    newTier = element;
                }
                if (element.type === 'used' && element.name === creditTierName) {
                    usedTier = element;
                }
            });

            const getCreditTierTableOptionsNew = () => {
                let creditTierTableOptionsNew: Options<any> = {};
                if (props.dealer) {
                    creditTierTableOptionsNew = {
                        pageSize: newTier.aprs.length,
                        pageSizeOptions: [newTier.aprs.length],
                        search: false
                    };
                }
                return creditTierTableOptionsNew;
            };
            const getCreditTierTableOptionsUsed = () => {
                let creditTierTableOptionsUsed: Options<any> = {};
                if (props.dealer) {
                    creditTierTableOptionsUsed = {
                        pageSize: usedTier.aprs.length,
                        pageSizeOptions: [usedTier.aprs.length],
                        search: false
                    };
                }
                return creditTierTableOptionsUsed;
            };

            const handleNewTierUpdate = (newData: IDealerCreditTierAPR, oldData: IDealerCreditTierAPR) => {
                if (!props.dealer) {
                    return null;
                }

                newTier.aprs[newTier.aprs.indexOf(oldData)] = newData;
                props.updateDealerCallback(null, { ...props.dealer });
            };

            const handleUsedTierUpdate = (newData: IDealerCreditTierAPR, oldData: IDealerCreditTierAPR) => {
                if (!props.dealer) {
                    return null;
                }
                usedTier.aprs[usedTier.aprs.indexOf(oldData)] = newData;
                props.updateDealerCallback(null, { ...props.dealer });
            };

            const handleAddNewAPR = (newData: IDealerCreditTierAPR) => {
                if (!props.dealer) {
                    return null;
                }
                const newAPR: IDealerCreditTierAPR = {
                    _id: '',
                    isDefault: false,
                    rate: newData.rate,
                    term: Number(newData.term)
                };
                newTier.aprs.push(newAPR);
                props.updateDealerCallback(null, { ...props.dealer });
            };

            const handleAddUsedAPR = (newData: IDealerCreditTierAPR) => {
                if (!props.dealer) {
                    return null;
                }
                const newAPR: IDealerCreditTierAPR = {
                    _id: '',
                    isDefault: false,
                    rate: newData.rate,
                    term: Number(newData.term)
                };
                usedTier.aprs.push(newAPR);
                props.updateDealerCallback(null, { ...props.dealer });
            };

            const handleDeleteNewAPR = (oldData: IDealerCreditTierAPR) => {
                if (!props.dealer) {
                    return null;
                }
                newTier.aprs.splice(newTier.aprs.indexOf(oldData), 1);
                props.updateDealerCallback(null, { ...props.dealer });
            };

            const handleDeleteUsedAPR = (oldData: IDealerCreditTierAPR) => {
                if (!props.dealer) {
                    return null;
                }
                usedTier.aprs.splice(usedTier.aprs.indexOf(oldData), 1);
                props.updateDealerCallback(null, { ...props.dealer });
            };

            if (!newTier || !usedTier) {
                return null;
            }
            return (
                <DR3FieldWrapper label="Credit and Financing Terms" creditTier={newTier}>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
                        <DR3CreditTierTable
                            creditTierData={newTier}
                            onUpdate={handleNewTierUpdate}
                            onAdd={handleAddNewAPR}
                            onDelete={handleDeleteNewAPR}
                            label={`New Vehicles | ${newTier.name}: ${newTier.lowScore} to ${newTier.highScore}`}
                            columns={columns}
                            tableOptions={getCreditTierTableOptionsNew()}
                        />
                        <DR3CreditTierTable
                            creditTierData={usedTier}
                            onUpdate={handleUsedTierUpdate}
                            onAdd={handleAddUsedAPR}
                            onDelete={handleDeleteUsedAPR}
                            label={`Used Vehicles | ${usedTier.name}: ${usedTier.lowScore} to ${usedTier.highScore}`}
                            columns={columns}
                            tableOptions={getCreditTierTableOptionsUsed()}
                        />
                    </div>
                </DR3FieldWrapper>
            );
        }
    };

    return <div>{creditTierNames.map(createCreditTierTables)}</div>;
};

const DR3PartnerMapping: FunctionComponent<IPartnerMapListUIProps> = (props) => {
    const partnerMappingColumn: any = [
        {
            title: 'Partner Name',
            field: 'integratorId',
            lookup: props.integrators,
            editable: 'onAdd'
        },
        {
            title: 'Partner Dealer Id',
            field: 'partnerDealerId',
            validate: (rowData: IdPartnerMapping) => {
                const validateFn = getValidateForId(rowData.integratorId);
                return validateFn ? validateFn(rowData) : true;
            }
        },
        {
            title: 'Quick links',
            field: 'quickLinks',
            editable: 'never',
            render: (rowData: IdPartnerMapping) => {
                return (
                    rowData.partnerDealerId &&
                    rowData.partnerDealerId !== '0' && (
                        <>
                            {rowData?.quickLinks?.map((link) => (
                                <a
                                    style={{ marginRight: 12 }}
                                    key={link.linkName}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={getQuickLinkValue(
                                        rowData.integratorId,
                                        link.linkName,
                                        rowData.partnerDealerId.toString()
                                    )}
                                >
                                    {link.linkName}
                                </a>
                            ))}
                        </>
                    )
                );
            }
        }
    ];

    const tableProcessedPartnerMappings = (partnerMappings: PartnerMapping[]) => {
        const processedPartnerMappings = [...partnerMappings];
        return processedPartnerMappings.filter((partnerMapping) => partnerMapping.status !== 'deleted');
    };

    const correctedPartnerMappingObject = (partnerMappingNewData: any) => {
        return {
            integratorId: partnerMappingNewData.integratorId,
            partnerDealerId: partnerMappingNewData.partnerDealerId?.toUpperCase().replace(/\s+/g, ''),
            status: partnerMappingNewData.status,
            ...partnerMappingNewData
        };
    };

    const handleIdChange = (newData: IdPartnerMapping) => {
        if (!props.dealer) return;

        const changeFn: ((value: any) => void) | undefined = getHandleChangeForId(
            newData.integratorId,
            props.dealer,
            props.updateDealerCallback
        );
        changeFn?.(newData.partnerDealerId);
    };

    const isIntegratorAlreadyOnPartnerMapping = (rowData: IdPartnerMapping) => {
        return !!props.partnerMappings.find((pm) => pm.integratorId === rowData.integratorId);
    };
    const handleAddNewPartnerMapping = (newData: any) => {
        if (isIntegratorAlreadyOnPartnerMapping(newData)) {
            return;
        }

        handleIdChange(newData);

        newData.status = 'new';
        const tmpPartnerMappings = [...props.partnerMappings].concat(correctedPartnerMappingObject(newData));
        props.updatePartnerMappingsCallback(null, tmpPartnerMappings);
    };
    const handleUpdatePartnerMapping = (newData: any, oldData: any) => {
        const validateFn = getValidateForId(newData.integratorId);
        if (validateFn && validateFn(newData) !== true) return;

        handleIdChange(newData);

        const tmpPartnerMappings = [...props.partnerMappings];
        const arrayPosition = tmpPartnerMappings.indexOf(oldData);
        newData.status = 'new';
        tmpPartnerMappings.splice(arrayPosition, 1, correctedPartnerMappingObject(newData));
        props.updatePartnerMappingsCallback(null, tmpPartnerMappings);
    };
    const handleDeletePartnerMapping = (newData: any) => {
        const tmpPartnerMappings = [...props.partnerMappings];
        tmpPartnerMappings.splice(tmpPartnerMappings.indexOf(newData), 1);

        props.updatePartnerMappingsCallback(null, tmpPartnerMappings);
    };
    const getPartnerMappingTableOptions = () => {
        return {
            pageSize: props.partnerMappings.length,
            pageSizeOptions: [props.partnerMappings.length],
            search: false
        };
    };
    return (
        <DR3PartnerMappingTable
            mappingData={tableProcessedPartnerMappings(props.partnerMappings)}
            columns={partnerMappingColumn}
            onUpdate={(newData, oldData) => handleUpdatePartnerMapping(newData as IdPartnerMapping, oldData)}
            onAdd={(e) => handleAddNewPartnerMapping(e)}
            onDelete={(e) => handleDeletePartnerMapping(e)}
            label="Partner Mapping"
            tableOptions={getPartnerMappingTableOptions()}
        />
    );
};

interface IGlobalFieldsSubSectionTextProps {
    title: string;
    hidden?: boolean;
    subtitle?: string;
}
const GlobalFieldsSubSectionText: FunctionComponent<IGlobalFieldsSubSectionTextProps> = ({ title, hidden, subtitle, children }) => {
    if (hidden) {
        return null;
    } else {
        return (
            <Box paddingTop="2em">
                <Typography align="center" gutterBottom variant="h5">
                    {title}
                </Typography>
                <Typography align="center" gutterBottom variant="h6" color="inherit">
                    {subtitle}
                </Typography>
                {children}
            </Box>
        );
    }
};

interface IGlobalFieldsSubSectionProps {
    title: string;
}
const GlobalFieldsSubSection: FunctionComponent<IGlobalFieldsSubSectionProps> = ({ title, children }) => {
    return (
        <Box paddingTop="2em">
            <Typography align="center" gutterBottom variant="h5">
                {title}
            </Typography>
            {children}
        </Box>
    );
};

export default function GlobalFieldsListUI(props: IGlobalFieldsListUIProps): JSX.Element {
    const classes = useStyles();
    const dealer = props.dealer;
    if (!dealer || props.dealerFieldsListSelected === false) {
        return <div />;
    }

    const creditAndFinancingTermsInfoLabel =
        'Please ensure that you update each Credit Tier for both New and Used vehicles with your latest Terms.';

    const dealershipInfoLabel = 'Please make sure to enter in all your basic dealership information.';

    const minimumFinanceRequirementsText =
        "You can prevent payments from loading on vehicles that don't " +
        'meet your finance requirements using these settings. Set your financeable limits using the Vehicle Mileage, ' +
        'Price, or Year restrictions.';

    const pilotFeaturesText = 'This section is only to be used by product and readiness teams!';

    const globalReservationsText =
        'These settings will only activate if you have the "Enable Reservations?" setting set to "Yes" under the Dealer Site Settings tab or Listing Site Settings tab.';

    const globalVppText =
        'These settings will only activate if you have the "Is Vehicle Protection Active?" setting set to "Yes" under the Dealer Site Settings tab or Listing Site Settings tab.';

    const hideLeasingTab = !dealer.products.hasLeasing;

    return (
        <ThemeProvider theme={themeOverride}>
            <GlobalFieldsWrapper classes={classes}>
                {props.admin && (
                    <Typography align="center" color="secondary" className={classes.adminMessage}>
                        ADMIN MODE
                        <br />
                        For end-to-end testing of hidden fields.
                    </Typography>
                )}
                <GlobalFieldsSection title="Product Set">
                    <ProductSet {...props} />
                </GlobalFieldsSection>
                <GlobalFieldsSection title="Inherit Settings From">
                    <SponsorSetting {...props} />
                </GlobalFieldsSection>
                <GlobalFieldsSection title="Dealership Info">
                    <Typography align="center" gutterBottom variant="h6">
                        {dealershipInfoLabel}
                    </Typography>
                    <DealershipNameField {...props} />
                    <DealershipAddressField {...props} />
                    <DealershipAddress2Field {...props} />
                    <DealershipCityField {...props} />
                    <DealershipStateField {...props} />
                    <DealershipZipField {...props} />
                    <DealershipPhoneField {...props} />
                    <DealershipWebsiteField {...props} />
                    <DealershipShopperEducationVideoURLField {...props} />
                </GlobalFieldsSection>

                <GlobalFieldsSection title="Partner Mappings">
                    <DR3PartnerMapping {...props} />
                </GlobalFieldsSection>

                <GlobalFieldsSection title="Admin Options">
                    <GlobalFieldsSubSection title="General" />
                    <IsTestDealer {...props} />
                    <UseZipCodeService {...props} />
                    <AccountStatus {...props} />
                    <GlobalShortDealerDisclaimer {...props} />
                    <GlobalLongDealerDisclaimer {...props} />
                    <OverrideDRSActiveField {...props} />
                    <SdpModalZIndex {...props} />
                    <ShowCreditApplicationDisclaimer {...props} />

                    <GlobalFieldsSubSection title="TCPA Consent Text Options" />
                    <div id="JumpToPaymentandCredit"></div>
                    <IsTCPAEnabled {...props} />
                    <ConsentType {...props} />
                    <TCPAConsentText {...props} />
                    <IsTCPACheckboxDisabled {...props} />
                    <IsPhoneRequiredForConsentDisplay {...props} />

                    <GlobalFieldsSubSection title="CCPA Consent Text Options" />
                    <IsCCPAEnabled {...props} />

                    <GlobalFieldsSubSection title="Payment and Credit" />
                    <HasLeasing {...props} />
                    <DefaultPaymentType {...props} />
                    <CalculateOnMSRP {...props} />
                    <ShowFlexiblePaymentOptions {...props} />
                    {props.admin && <PaymentRange {...props} />}
                    {props.admin && <FinanceRangeAmount {...props} />}
                    {props.admin && <LeaseRangeAmount {...props} />}
                    <EnableItemizedManufacturerIncentives {...props} />
                    <ApplyTaxesAndFees {...props} />
                    <CreditProvider {...props} />
                    {props.admin && <IsTargetPlatformR1J {...props} />}
                    <StandaloneAccelerateCreditAppUrl {...props} />
                    <DealershipCreditApplicationURLField {...props} />
                    <DealershipCreditApplicationURLButtonText {...props} />
                    <div id="JumpToIncentives"></div>
                    <IsEmployerAddressRequired {...props} />
                    <IsAccelerateCreditAppEnabled {...props} />
                    {props.admin && <EnableSoftPull {...props} />}
                    <EnableLeadFormBeforeCreditApp {...props} />
                    <CustomizedPrivacyNotice {...props} />

                    <GlobalFieldsSubSection title="Credit Decisions" />
                    <HasCreditDecision {...props} />
                    <IsAccelerateCreditDecisionEnabled {...props} />
                    <IsConsumerFacingCreditDecisionEnabled {...props} />
                    <ConsumerFacingCreditDecisionType {...props} />
                    <ShouldSendCreditDecisionEmail {...props} />

                    <GlobalFieldsSubSection title="Vehicle Test Drive" />
                    <TestDriveServiceProvider {...props} />
                    <TestDriveAvailability {...props} />
                    <div id="JumpToCRM"></div>

                    <GlobalFieldsSubSection title="CRM" />
                    <LocationToSendLeadsNew {...props} />
                    <LocationToSendLeadsUsed {...props} />
                    <UseDealertrackLeadOverride {...props} />
                    <div id="JumpToConsumerExperienceOptions"></div>
                    <VinSolutionsCRMIntegration {...props} />

                    <GlobalFieldsSubSection title="Consumer Experience Options" />
                    <IsDefaultHeaderTextEnabled {...props} />
                    <IsPhoneNumberRequired {...props} />
                    <div id="JumpToGlobalReservations"></div>
                </GlobalFieldsSection>

                <GlobalFieldsSubSectionText title="Reservations" subtitle={globalReservationsText} />
                <ReservationType {...props} />
                <HoldPeriod {...props} />
                <ReservationAmount {...props} />
                <DepositType {...props} />
                <VantivAccountId {...props} />
                <VantivTerminalId {...props} />
                <VantivAcceptorId {...props} />
                <div id="JumpToGlobalVPP"></div>
                <VantivAccountToken {...props} />

                <GlobalFieldsSubSectionText title="Vehicle Protection" subtitle={globalVppText} />
                <IsMenuPricingDisabled {...props} />
                <IsMenuPricingBasedOn {...props} />
                <IsMenuFitToHeightEnabled {...props} />

                <GlobalFieldsSection title="Credit and Financing">
                    <GlobalFieldsSubSection title="Default Credit Tiers" />
                    <CreditAndFinancingNewDropdown {...props} />
                    <CreditAndFinancingUsedDropdown {...props} />

                    <GlobalFieldsSubSection title="Financing" />
                    <FinancingDefaultTermDropdowns {...props} />
                    <CashDownMethodFinancing {...props} />
                    <DownPaymentPercent {...props} />
                    <DownPaymentPercentUsed {...props} />
                    <DownPaymentFixedAmountNew {...props} />
                    <DownPaymentFixedAmountUsed {...props} />

                    <GlobalFieldsSubSectionText title="Minimum Finance Requirements" subtitle={minimumFinanceRequirementsText} />
                    <FinancingMileageLimit {...props} />
                    <FinancingPriceLimit {...props} />
                    <FinancingYearLimit {...props} />

                    <GlobalFieldsSubSectionText title="Credit and Financing Terms" subtitle={creditAndFinancingTermsInfoLabel} />
                    <DR3CreditTierList {...props} />
                    <NewVehiclePoorCreditDropdown {...props} />
                    <UsedVehiclePoorCreditDropdown {...props} />
                </GlobalFieldsSection>

                <GlobalFieldsSection title="Leasing" hidden={hideLeasingTab}>
                    <GlobalFieldsSubSection title="Leasing Terms" />
                    <MultipleSecurityDepositLease {...props} />
                    <DueAtSigning {...props} />
                    <DisplayUnifIDealSummary {...props} />
                    <LeasingMileageDefaultDropdown {...props} />
                    <LeasingTermDefaultDropdown {...props} />
                    <LeasePaymentBasedOnMsrp {...props} />
                    <CashDownMethodLeasing {...props} />
                    <LeasingDownPaymentFixedAmount {...props} />
                    <LeasingDownPaymentPercent {...props} />
                    <HowToApplyDownPayment {...props} />
                    <DR3LeasingTermsList {...props} />
                </GlobalFieldsSection>

                <GlobalFieldsSection title="Print">
                    <PrintSignatureLine {...props} />
                    <ShowPrintItemizedDealBreakdown {...props} />
                </GlobalFieldsSection>

                <GlobalFieldsSection title="Pilot Features">
                    <Typography align="center" color="error">
                        {pilotFeaturesText}
                    </Typography>
                    <HasTestDriveAtHome {...props} />
                    <IsVehicleDeliveryEnabled {...props} />
                    {/* TODO: US796965 - Remove Live person chat toggle from admin and any associated code */}
                    {/* <LivePersonChatUpgrade {...props} /> */}
                    {/* <LivePersonChatUpgradeId {...props} /> */}
                    <DealLookupExperience {...props} />
                    <IsDocUploadEnabled {...props} />
                    <IsActivEngageEnabled {...props} />
                    {props.admin && <IsOccIntegrationEnabled {...props} />}
                </GlobalFieldsSection>
                {props.adminInternal && (
                    <GlobalFieldsSection title="Internal Settings">
                        <Typography align="center" color="error">
                            Warning! This section contains toggles that are permanently for internal use only.
                        </Typography>
                        <AreDealerSettingOverridesEnabled {...props} />
                    </GlobalFieldsSection>
                )}

                <GlobalFieldsSection title="Deal Central Options">
                    <div id="JumpToDealCentralOptions"></div>
                    <DealCentralCMSIntegration {...props} />
                    <EnableVauto {...props} />
                    <EnableSwitchVehicle {...props} />
                    <ShowroomExperience {...props} />
                    <IsContinueToDeskingDisabled {...props} />
                    <EnableCheckoutAnywhereInMV {...props} />
                    <EnableCheckoutAnywhereInSV {...props} />
                    {props.admin && (
                        <>
                            <ShowDealPulseInManagerView {...props} />
                            <EnableGhostVin {...props} />
                        </>
                    )}
                    {props.featureToggles?.enablePushToDarwin && <EnableDarwin {...props} />}
                    {props.admin && <EnableGenerateContracts {...props} />}
                    <DealScreenExperience {...props} />
                </GlobalFieldsSection>
                {props.admin && (
                    <GlobalFieldsSection title="Ecommerce">
                        <EcommercePartnerDropdown {...props} />
                        <EcommerceURL {...props} />
                        <EcommerceCTACopy {...props} />
                    </GlobalFieldsSection>
                )}
            </GlobalFieldsWrapper>
        </ThemeProvider>
    );
}
