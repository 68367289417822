import React, { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: boolean): void => {
        const enableGenerateContracts = value;
        const newDealer = {
            ...oldDealer,
            enableGenerateContracts
        };
        updateDealerCallback(null, newDealer);
    };
}

const tooltip =
    'Turning on "Generate Contracts" will display a button on the Deal Screen that will allow a user to automatically ' +
    'generate contracts. "Generate Contracts" requires dealership access to the Digital Deal Jacket(DDJ) to work because ' +
    'DDJ is required to send the contracts to the buyer for digital signing.';

const EnableGenerateContracts: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }
    const value = !!props.dealer.enableGenerateContracts;
    return (
        <DR3RadioBooleanField
            trueOption="Yes"
            falseOption="No"
            label={ToggleLabelMap.enableGenerateContracts}
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            tooltipProps={tooltip}
        />
    );
};
export default EnableGenerateContracts;
